import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import {
  Section,
  SectionTitle,
  SectionDescription,
  SectionHeader,
  SectionPageTitle,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
  BreadCrumb,
} from "../components/section";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ProductForm from "../sections/Product/ProductForm";
import ProductCardList from "../components/TabProductCardList";
import RequestQuote from "../components/RequestQuote";

import DefaultButton from "../components/button/DefaultButton";
import DefaultOutlineButton from "../components/button/DefaultOutlineButton";
import PhoneIcon from "../components/Icons/PhoneIcon";
import QouteIcon from "../components/Icons/QouteIcon";
import BreakpointUp from "../components/Media/BreakpointUp";
import QuotePopup from "../components/QuotePopup";
import {ExploreMore} from "../components/section";
import generateHTML from "../utils/generateHTML";
import Content, { HTMLContent } from "../components/Content";

const SectionCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
`;
const CoverFigure = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 100%;
  display: none;
  ${BreakpointUp.lg`   
    display:block;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 360px;
    margin: 20px;
    z-index: 1;
  }
`;
const CoverImg = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -20px;
    max-width: 194px;
    max-height: 194px;
    width: 100%;
    height: 100%;
    background-color: #0a4e82;
  }
`;
const CoverContent = styled.div`
  position: relative;
  padding: 0 15px;
  width: 100%;
  ${BreakpointUp.lg`          
    flex: 0 0 50%;
    max-width: 50%;
  `}
  & p {
    margin-bottom: 30px;
  }
`;

const ButtonToolbar = styled.div`
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: inherit;
  ${BreakpointUp.sm`
    flex-direction:columns;
  `}
  & a, .quote-btn {
    padding: 0 10px;
    + .quote-btn {
      margin-top: 10px;
      ${BreakpointUp.sm`
        margin-top:0px;
      `}
    }
  }
`;

const Nodata = styled.div`
	text-align: center;
	padding: 15px 0;
  ${BreakpointUp.lg`
		padding:30px 0;
	`}
`
const NodateContent = styled.div`
	max-width:740px;
	width:100%;
	margin:0 auto;
`
const NodateText = styled.div`
	& p {
		margin-bottom:0;		
		font-weight:500;
		${BreakpointUp.lg`
			font-size:20px;
			line-height:30px;
		`}
	}
`

class ProductRoofLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    };
    this.showQuote = this.showQuote.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open");
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open");
    }
  }

  render() {
    const { isVisibleQuote } = this.state;
    const { data } = this.props;
    const pageData = data.contentfulProductRoofLanding;
    const PostContent = HTMLContent || Content;
    const productList = data.allContentfulProduct;
    const productsData = [];
    productList.edges.forEach(function (item) {
      if ( item.node.productSubCategory &&
        item.node.productSubCategory.some(
          (sub) => sub.id === pageData.productSubcategory.id
        )
      ) {
        productsData.push(item);
      }
    });

    return (
      <Layout>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
        />
        <SectionHeader
          pt="140px"
          pb="90px"
          xpt="110px"
          xpb="60px"
          bgColor="#F4F8FC"
        >
          <div className="container">
            <SectionCover>
              <CoverContent>
                <BreadCrumb>
                  <Link to="/">Home</Link>/
                  <Link to={pageData.productCateogry.link}>
                    {pageData.productCateogry.name}
                  </Link>
                  /<span>{pageData.title}</span>
                </BreadCrumb>
                <SectionPageTitle mt="30px" mb="15px" textAlign="left">
                  {pageData.title}
                </SectionPageTitle>
                <SectionDescription textAlign="left">
                  <PostContent
                    content={generateHTML(
                      pageData.heroDescription.childMarkdownRemark.html
                    )}
                  />
                </SectionDescription>
                <ButtonToolbar>
                  <a href="tel:8773505464">
                    <DefaultButton icon={<PhoneIcon />} text="(877) 350-5464" />
                  </a>
                  <span
                    className="quote-btn"
                    onClick={this.showQuote}
                    onKeyDown={this.showQuote}
                    aria-label="Request A Quote"
                    role="button"
                    tabIndex="0"
                  >
                    <DefaultOutlineButton
                      icon={<QouteIcon />}
                      text="Request A Quote"
                    />
                  </span>
                </ButtonToolbar>
              </CoverContent>
              <CoverFigure>
                <CoverImg>
                  <Img fluid={pageData.image.fluid} />
                </CoverImg>
              </CoverFigure>
            </SectionCover>
          </div>
        </SectionHeader>
        <QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
        <Section pt="70px" pb="70px" xpt="60px" xpb="60px" bg="#fff">
          <div className="container">
            <SectionTitle maxWidth="1120px">
              {pageData.productListSectionTitle}
            </SectionTitle>
            <SectionDescription mb="50px" maxWidth="1120px">
              {
                pageData.productListSectionDescription
                  .productListSectionDescription
              }
            </SectionDescription>
            {productsData.length > 0 ? (
              <ProductCardList
                roof="true"
                data={productsData}
                buttonTxt="Explore all Buildings"
                buttonLink="/products"
                showQuote={this.showQuote}
              />
            ) : (
              <Nodata>
                <NodateContent>                  
                  <NodateText>
                    <p>Currently we have not added any product in this category</p>                    
                  </NodateText>
                </NodateContent>               
              </Nodata>
            )}
          </div>
        </Section>
        <Section pt="0" pb="0"><RequestQuote ctaTitle="Looking For a Metal Building Which is Specially Designed for You?" ctaDesc="We Have Exactly the One You Need, Connect With Our Experts." /></Section>
        <Section pt="90px" pb="90px" bg="#F4F8FC">
          <div className="container">
            <MainPanel>
              <LeftPannel>
                <MarkdownContent>
                  <PostContent
                    content={generateHTML(
                      pageData.content.childMarkdownRemark.html
                    )}
                  />
                </MarkdownContent>
              </LeftPannel>
              <RightPannel>
                <ProductForm />
              </RightPannel>
            </MainPanel>
          </div>
        </Section>
      </Layout>
    );
  }
}

export default ProductRoofLanding;

export const pageQuery = graphql`
  query ProductRoofLandingQuery($id: String!) {
    contentfulProductRoofLanding(id: { eq: $id }) {
      metaTitle
      metaDescription
      title
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      roofName {
        roofName
      }
      productListSectionTitle
      productListSectionDescription {
        productListSectionDescription
      }
      image {
        fluid {
          src
          base64
          aspectRatio
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      productCateogry {
        name
        link
      }
      productSubcategory {
        id
      }
    }
    allContentfulProduct(sort: { fields: totalPrice }) {
      edges {
        node {
          productName
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          popularLabel
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
          popularLabel
          url
          productCategory {
            name
          }
          productSubCategory {
            id
          }
        }
      }
    }
  }
`;
